<template>
    <v-select
      v-model = "input"
      :items = "obj.schema.items"
      :item-text = "obj.schema.itemText"
      :item-value = "obj.schema.itemValue"
      :label = "obj.schema.label"
      :outlined = "!obj.schema.disabled"
      :hideDetails = "true"
      :dense = "true"
      :disabled = "obj.schema.disabled"
      class = "mt-2"
    ></v-select>
</template>
<script>
export default {
  name:'customSelect',
  props: ['type','value', 'obj'],
  computed:{
    input:{
      get(){  return this.value},
      set(val){ this.$emit('input', val)}  // listen to @input="handler"
    }
  }
}
</script>
